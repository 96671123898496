import React from 'react';
import styled from 'styled-components';
import { Container, Col, Row } from 'reactstrap';

import Contact from '../components/contact';

import CardBoard from '../components/cardboard';
import Layout from '../layouts';
import SEO from '../components/seo';

const WorkshopContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.blackWhite};
  padding: 1.2rem;
  margin-top: -110px;

  @media (max-width: 960px) {
    text-align: center;
  }
`;

const Title = styled.h1`
  margin-bottom: 4rem;
  font-size: 2rem;
  white-space: nowrap;
  overflow-wrap: normal;
  max-width: 1200px;

  @media (min-width: 960px) {
    font-size: 3.6rem;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

const Image = styled.img`
  border-radius: 10px;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    margin-bottom: 0;
    object-fit: cover;
  }
`;

const Text = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

const Topic = styled.h2`
  font-size: 2rem;
  font-weight: bold;
`;


const WarsztatyPage = (props) => {
  return (
    <Layout path={props.location.pathname}>
      <SEO title="Warsztaty" path={props.location.pathname} />
      <WorkshopContainer>
        <Container style={{paddingTop:100}}>
          <Title>Warsztaty</Title>
          <Section>
            <Row>
              <Col xs="12" lg="6">
                <Image
                  src="https://pliki.sadyogrody.pl/i/03/19/65/031965_r0_980.jpg"
                  alt="Workshop 1"
                />
              </Col>
              <Col xs="12" lg="6">
                <Topic>Warsztaty tematyczne</Topic>
                <Text>
                W naszym eko zakątku oferujemy nie tylko wyjątkowy wypoczynek wśród natury, ale również tematyczne warsztaty, dedykowane szczególnie kobietom w trudnych chwilach życiowych.<br/><br/>
                Pragniemy stworzyć przestrzeń, w której możesz znaleźć wsparcie, odnaleźć równowagę i rozwijać się zarówno duchowo, jak i emocjonalnie. Nasze warsztaty obejmują różnorodne tematy, takie jak medytacja, jogę, sztukę terapeutyczną, a także inne praktyki i techniki, które pomagają odnaleźć spokój i siłę wewnętrzną.<br/><br/>
                Zapraszamy Cię do odkrycia siły i potencjału, który tkwi w Tobie. Przyjdź do naszego eko zakątka i dołącz do naszych warsztatów, aby poszerzyć swoją wiedzę, znaleźć inspirację i zbudować więź z silną społecznością kobiet. Razem możemy pokonać trudności i wspólnie rozwijać się na drodze do pełniejszego i bardziej harmonijnego życia.
                </Text>
              </Col>
            </Row>
          </Section>
          <Section>
            <Row>
              <Col xs="12" lg="6">
                <Topic>Joga</Topic>
                <Text>
                Podczas naszych warsztatów jogi, doświadczysz doskonałego połączenia ruchu, 
                oddechu i medytacji. Prowadzone przez doświadczonych instruktorów, zajęcia dopasowane są zarówno 
                dla początkujących, jak i zaawansowanych praktykujących. Odkryj moc i spokój, jakie płyną z 
                praktyki jogi, i naucz się narzędzi do codziennego odprężenia i równowagi.
                </Text>
              </Col>
              <Col xs="12" lg="6">
                <Image
                  src="https://i.wpimg.pl/730x0/m.fitness.wp.pl/shutterstock-207304351-bed1e0013.jpg"
                  alt="Workshop 2"
                />
              </Col>
            </Row>
          </Section>
          <Section>
            <Row>
              <Col xs="12" lg="4">
              <div class="fb-page"      	
              data-href="https://www.facebook.com/profile.php?id=61551886345786" data-tabs="timeline" data-width="" data-height="" data-small-header="true" data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="false"><blockquote cite="https://www.facebook.com/profile.php?id=61551886345786" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/profile.php?id=61551886345786">Eko Zakątek</a></blockquote></div>
              </Col>
              <Col xs="12" lg="8">
                <Topic>📢 Bądź na bieżąco z naszymi wydarzeniami i ofertami na Facebooku! 🌟</Topic>
                <Text>Chcemy, abyście zawsze byli informowani o naszych aktualnościach, warsztatach i wyjątkowych okazjach. Dlatego zachęcamy Was do śledzenia naszej strony na Facebooku, gdzie znajdziecie najnowsze informacje i inspiracje.</Text>        
                <Text>Przejdź na naszą stronę na Facebooku, kliknij "Lubię to!" i dołącz do naszej społeczności. Dzięki temu nigdy nie przegapisz żadnego ciekawego wydarzenia.</Text>
              </Col>
              
            </Row>
          </Section> 
          <Section style={{ padding: "2rem", backgroundColor: "#f9f9f9", borderRadius: "8px" }}>
  <Row>
    <Col xs="12" lg="6">
      <Topic style={{ fontSize: "1.8rem", fontWeight: "bold", color: "#4CAF50", marginBottom: "1rem" }}>
        🌿 Weekendowy relaks dla kobiet w Eko Zakątku w górach – 750 zł/osoba 🌄
      </Topic>

      <p style={{ fontSize: "1rem", lineHeight: "1.6", color: "#333" }}>
        ✨ Oderwij się od codzienności i zanurz w harmonii natury!  
        Zapraszamy na wyjątkowy weekend w malowniczym <b>Eko Zakątku</b>, dedykowany wyłącznie kobietom.  
        To idealna okazja, aby odpocząć w otoczeniu górskiego krajobrazu, zadbać o ciało i duszę, a przede wszystkim 
        zregenerować siły w gronie podobnie myślących kobiet. 🌸
      </p>

      <h2 style={{ fontSize: "1.5rem", fontWeight: "bold", color: "#444", marginTop: "1.5rem" }}>🌟 Co oferujemy?</h2>

      <div style={{ marginTop: "1rem",color: "#333" }}>
        <b>🏡 Komfortowe zakwaterowanie:</b>
        <p style={{ marginBottom: "0.5rem", color: "#555" }}>
          2 noclegi w dwóch przytulnych, ekologicznych domkach, z przestronnym salonem i dwoma dwuosobowymi pokojami – idealne warunki do relaksu i rozmów przy kominku. 🔥
        </p>

        <b>🍴 Pełne wyżywienie:</b>
        <p style={{ marginBottom: "0.5rem", color: "#555" }}>🍳 2 śniadania i 2 obiadokolacje, w tym pyszne desery. 🧁</p>
        <p style={{ marginBottom: "0.5rem", color: "#555" }}>🥗 Opcja wegetariańska dostępna na życzenie.</p>

        <b>💆‍♀️ Zabiegi na ciało i duszę:</b>
        <p style={{ marginBottom: "0.5rem", color: "#555" }}>✨ Masaż twarzy – regeneracja i blask dla Twojej skóry.</p>
        <p style={{ marginBottom: "0.5rem", color: "#555" }}>🛁 Całodzienny relaks w jacuzzi z widokiem na malownicze góry.</p>
        <p style={{ marginBottom: "0.5rem", color: "#555" }}>🔥 2-godzinny seans w saunie – oczyszczenie i odprężenie organizmu.</p>

        <b>🌳 Aktywności w zgodzie z naturą:</b>
        <p style={{ marginBottom: "0.5rem", color: "#555" }}>🧘‍♀️ Poranne zajęcia jogi – harmonia ciała i umysłu.</p>
        <p style={{ marginBottom: "0.5rem", color: "#555" }}>🚴‍♀️ Wycieczka lub wyjazd rowerowy w góry – świeże powietrze i niezapomniane widoki.</p>
      </div>
    </Col>

    <Col xs="12" lg="6">
      <Image
        src="https://firebasestorage.googleapis.com/v0/b/eco-zakatek.appspot.com/o/wydarzenia%2Frelaks_dla_kobiet.png?alt=media&token=dd0f6320-5a26-4355-ba21-39b2868aeb84"
        alt="Weekendowy relaks w górach"
        style={{
          width: "100%",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      />
    </Col>
  </Row>
</Section>

<Section>
  <Row>
    <Col xs="12" lg="6">
      <Image
        src="https://firebasestorage.googleapis.com/v0/b/eco-zakatek.appspot.com/o/wydarzenia%2Findygo.png?alt=media&token=5121faf2-6aca-4071-9c6a-eb2fba1ffbf7&_gl=1*ht12a4*_ga*MTUzODUwODI1MC4xNjgyMjcwMjg4*_ga_CW55HF8NVT*MTY5ODgzNTg2OC4yNS4xLjE2OTg4MzU5MTguMTAuMC4w" // Dodaj URL obrazu
        alt="Akademia Indygo"
      />
    </Col>
    <Col xs="12" lg="6">
      <h2>Archiwum Wydarzeń</h2>
      <Topic> Warsztaty "Światło w Cieniu"</Topic>
      <p>
        Przyjdź na wyjątkowe warsztaty w naszym klimatycznym Eko Zakątku u podnóża góry Ostre! 🌒
      </p>
      <p>
         Odkryj wewnętrzne światło, zgłębiając warstwy swojej istoty.
      </p>
      <p>
        🔥 Doświadcz praktyk, które pomogą Ci odnaleźć równowagę i siłę wewnętrzną.
      </p>
      <p>
        🌄 Wspólnie wyrusz w podróż do Twojego cienia i odkryj, co jaśnieje najmocniej.
      </p>
      <p>
        📅 Data: 24-26 Listopad 2023
      </p>
      <p>
        🌿 Lokalizacja: Eko-Zakątek, Ostre
      </p>
      <p>
        🌎 Warsztaty obejmują poznawanie symbolii w twoim życiu, medytacje, pracę ze snami i wiele więcej.
      </p>
      <p>
        🌹 W pakiecie: zakwaterowanie, wyżywienie, jacuzzi i sauna.
      </p>
      <p>
        Zapisz się już teraz i odkryj potencjał, który tkwi w Tobie!
      </p>
      <p>
        Więcej informacji: <a href="https://www.facebook.com/events/2111561515857235">Zapisz się</a>
      </p>
      <p>
        #Warsztaty #RozwójOsobisty #EkoZakątek #Ostre #ŚwiatłoWCieniu
      </p>
    </Col>    
  </Row>
</Section>
          
        </Container>
     
      </WorkshopContainer>
      <Contact />
   </Layout>
  );
};

export default WarsztatyPage;